<template>
  <div class="ml-4">
    <strong>{{ entryKey }}</strong> :

    <template v-if="isStringOrNumber(entryValue)">
      {{ entryValue }}
    </template>

    <!-- Here, the component nests itself if value is not a string/number -->
    <template v-else>
        <object-entry          
          v-for="(value, key) in entryValue"
          :key="key"
          :entry-key="key"
          :entry-value="value"
        />
    </template>

  </div>
</template>

<script>
export default {
  name: "ObjectEntry",
  // eslint-disable-next-line
  props: ['entryKey', 'entryValue'],
  methods: {
    isStringOrNumber: function (v) {
      return typeof v === "string" || typeof v === "number" || typeof v === "boolean";
    },
  },
};
</script>