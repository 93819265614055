<!-- Operation APIs in Portal (ONLY CAPADMIN - fabian@chimaera.my and yuenwei.loh@globalline.my CAN ACCESS) -->
<template>
  <div id="operation-page">
    <breadcrumb title="CAP Admin" subtitle="Operation Management" />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <b-card no-body>
          <h1 style="text-align: center;">API: {{ apiStatus }}</h1>
          <b-tabs pills card vertical
            content-class="mt-3"
            nav-wrapper-class="content" 
            align="right"
            @input="resetForm"
          >
            <!-- DELETE PROMOTER TARGET -->
            <b-tab title="Delete Promoter Target" active>
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <label class="text-label">Fiscal Year</label>
                    <Multiselect
                        ref="year"
                        v-model="filters.yearSelected"
                        :searchable="false"
                        :options="yearOptions"
                        label="name"
                        track-by="value"
                        :loading="!yearOptions"
                        :allow-empty="false"
                        :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="col-lg-4">
                    <label class="text-label">Quarter</label>
                    <Multiselect
                        ref="quarter"
                        v-model="filters.quarterSelected"
                        :searchable="false"
                        :options="quarterOptions"
                        label="name"
                        track-by="value"
                        :loading="!quarterOptions"
                        :allow-empty="false"
                        :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="deletePromoterTarget"
                    >
                      Submit
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>

                </div>
                <small> API : {{ API_DELETE_PROMOTER_TARGET }}</small>
              </div>            
            </b-tab>

            <!-- GET DEALER INFO -->
            <b-tab title="Get Dealer Info">
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group flex-grow-1">
                      <label class="text-label">MDM Code</label>
                      <span class="required-star">*</span>
                      <input
                        v-model="textInput"
                        name="mdm"
                        :disabled="isLoading"
                        type="text"
                        class="form-control"
                        placeholder 
                        @keyup="formErrors.text = ''"
                      />
                      <span v-if="formErrors.text" class="invalid-field">{{ formErrors.text }}</span>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="getDealerInfo"
                    >
                      Submit
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>
                </div>
                <small> API : {{ API_DEALER_INFO }}</small>
              </div>
            </b-tab>

            <!-- GET OUTLET INFO -->
            <b-tab title="Get Outlet Info">
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group flex-grow-1">
                      <label class="text-label">Outlet Code</label>
                      <span class="required-star">*</span>
                      <input
                        v-model="textInput"
                        name="mdm"
                        :disabled="isLoading"
                        type="text"
                        class="form-control"
                        placeholder 
                        @keyup="formErrors.text = ''"
                      />
                      <span v-if="formErrors.text" class="invalid-field">{{ formErrors.text }}</span>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="getOutletInfo"
                    >
                      Submit
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>

                </div>
                <small> API : {{ API_OUTLET_INFO }}</small>
              </div>
            </b-tab>

            <!-- EXPORT COMPARISON REPORT -->
            <b-tab title="Export Comparison Report">
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <label class="text-label">Country</label>
                    <Multiselect
                      ref="country"
                      v-model="filters.countrySelected"
                      :searchable="false"
                      :options="countryOptions"
                      label="name"
                      track-by="value"
                      :loading="!countryOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="col-lg-4">
                    <label class="text-label">Fiscal Year</label>
                    <Multiselect
                        ref="year"
                        v-model="filters.yearSelected"
                        :searchable="false"
                        :options="yearOptions"
                        label="name"
                        track-by="value"
                        :loading="!yearOptions"
                        :allow-empty="false"
                        :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="col-lg-4">
                    <label class="text-label">Quarter</label>
                    <Multiselect
                        ref="quarter"
                        v-model="filters.quarterSelected"
                        :searchable="false"
                        :options="quarterOptions"
                        label="name"
                        track-by="value"
                        :loading="!quarterOptions"
                        :allow-empty="false"
                        :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="exportComparisonReport"
                    >
                      Export
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>

                </div>
                <small> API : {{ API_EXPORT_COMPARISON_REPORT }}</small>
              </div>
            </b-tab>

            <!-- GET MTM -->
            <b-tab title="Get MTM">
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group flex-grow-1">
                      <label class="text-label">MTM</label>
                      <span class="required-star">*</span>
                      <input
                        v-model="textInput"
                        name="mdm"
                        :disabled="isLoading"
                        type="text"
                        class="form-control"
                        placeholder 
                        @keyup="formErrors.text = ''"
                      />
                      <span v-if="formErrors.text" class="invalid-field">{{ formErrors.text }}</span>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="getMTM"
                    >
                      Submit
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>

                </div>
                <small> API : {{ API_GET_MTM }}</small>
              </div>
            </b-tab>

            <!-- GET SERIAL -->
            <b-tab title="Get Serial">
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group flex-grow-1">
                      <label class="text-label">Serial</label>
                      <span class="required-star">*</span>
                      <input
                        v-model="textInput"
                        name="serial"
                        :disabled="isLoading"
                        type="text"
                        class="form-control"
                        placeholder 
                        @keyup="formErrors.text = ''"
                      />
                      <span v-if="formErrors.text" class="invalid-field">{{ formErrors.text }}</span>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="getSerial"
                    >
                      Submit
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>

                </div>
                <small> API : {{ API_GET_SERIAL }}</small>
              </div>
            </b-tab>

            <!-- GET USER -->
            <b-tab title="Get User">
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group flex-grow-1">
                      <label class="text-label">Email</label>
                      <span class="required-star">*</span>
                      <input
                        v-model="textInput"
                        name="serial"
                        :disabled="isLoading"
                        type="text"
                        class="form-control"
                        placeholder 
                        @keyup="formErrors.text = ''"
                      />
                      <span v-if="formErrors.text" class="invalid-field">{{ formErrors.text }}</span>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="getUser"
                    >
                      Submit
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>

                </div>
                <small> API : {{ API_GET_USER }}</small>
              </div>
            </b-tab>

            <!-- REFRESH DASHBOARD -->
            <b-tab title="Refresh Dashboard">
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <label class="text-label">Country</label>
                    <Multiselect
                      ref="country"
                      v-model="filters.countrySelected"
                      :searchable="false"
                      :options="countryOptions"
                      label="name"
                      track-by="value"
                      :loading="!countryOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="col-lg-4">
                    <label class="text-label">Fiscal Year</label>
                    <Multiselect
                        ref="year"
                        v-model="filters.yearSelected"
                        :searchable="false"
                        :options="yearOptions"
                        label="name"
                        track-by="value"
                        :loading="!yearOptions"
                        :allow-empty="false"
                        :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="col-lg-4">
                    <label class="text-label">Quarter</label>
                    <Multiselect
                        ref="quarter"
                        v-model="filters.quarterSelected"
                        :searchable="false"
                        :options="quarterOptions"
                        label="name"
                        track-by="value"
                        :loading="!quarterOptions"
                        :allow-empty="false"
                        :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="refreshDashboard"
                    >
                      Refresh
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>
                </div>
                <small> API : {{ API_REFRESH_DASHBOARD }}</small>
              </div>
              <b-alert show variant="danger"> **** HEAVY PROCESSING **** </b-alert>
            </b-tab>

            <!-- UPLOAD REVCA -->
            <b-tab title="Upload REVCA">
              <div>
                <div class="row">
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="$refs.file.click();uploadFunc = uploadREVCA"
                    > 
                      <i class="fa fa-upload"></i>
                      Upload
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>
                </div>
                <small> API : {{ API_UPLOAD_REVCA }}</small>
              </div>
            </b-tab>
            
            <!-- UPLOAD MTM -->
            <b-tab title="Upload MTM">
              <div>
                <div class="row">
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="$refs.file.click();uploadFunc = uploadMTM"
                    > 
                      <i class="fa fa-upload"></i>
                      Upload
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>
                </div>
                <small> API : {{ API_UPLOAD_MTM }}</small>
              </div>
            </b-tab>

            <!-- DELETE SALES -->
            <b-tab title="Delete Sales">
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group flex-grow-1">
                      <label class="text-label">Sales Transaction ID</label>
                      <span class="required-star">*</span>
                      <input
                        v-model="textInput"
                        name="mdm"
                        :disabled="isLoading"
                        type="text"
                        class="form-control"
                        placeholder 
                        @keyup="formErrors.text = ''"
                      />
                      <span v-if="formErrors.text" class="invalid-field">{{ formErrors.text }}</span>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="deleteSales"
                    >
                      Submit
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>

                </div>
                <small> API : {{ API_DELETE_SALES }}</small>
              </div>
            </b-tab>

            <!-- UPDATE USER STATUS -->
            <b-tab title="Update User Status">
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group flex-grow-1">
                      <label class="text-label">Email</label>
                      <span class="required-star">*</span>
                      <input
                        v-model="textInput"
                        name="mdm"
                        :disabled="isLoading"
                        type="text"
                        class="form-control"
                        placeholder 
                        @keyup="formErrors.text = ''"
                      />
                      <span v-if="formErrors.text" class="invalid-field">{{ formErrors.text }}</span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label class="text-label">Status</label>
                    <div class="form-group mb-0">
                      <label class="switch" for="switchInput">
                        <input id="switchInput" v-model="isChecked" type="checkbox" />
                        <div class="slider round"></div>
                      </label>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="updateUserStatus"
                    >
                      Submit
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>

                </div>
                <small> API : {{ API_UPDATE_USER_STATUS }}</small>
              </div>
            </b-tab>

            <!-- BACKEND SO -->
            <b-tab title="Backend SO">
              <div>
                <div class="row">
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="$refs.file.click();uploadFunc = backendSO"
                    > 
                      <i class="fa fa-upload"></i>
                      Upload
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>
                </div>
                <small> API : {{ API_BACKEND_SO }}</small>
              </div>
            </b-tab>

            <!-- UPDATE USER ROLE -->
            <b-tab title="Update User Role">
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group flex-grow-1">
                      <label class="text-label">Email</label>
                      <span class="required-star">*</span>
                      <input
                        v-model="textInput"
                        name="serial"
                        :disabled="isLoading"
                        type="text"
                        class="form-control"
                        placeholder 
                        @keyup="formErrors.text = ''"
                      />
                      <span v-if="formErrors.text" class="invalid-field">{{ formErrors.text }}</span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label class="text-label">User Role</label>
                    <Multiselect
                      ref="role"
                      v-model="filters.userRoleSelected"
                      :searchable="false"
                      :options="userRoleOptions"
                      label="role"
                      track-by="id"
                      :loading="!userRoleOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="updateUserRole"
                    >
                      Submit
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>

                </div>
                <small> API : {{ API_UPDATE_USERROLE }}</small>
              </div>            
            </b-tab>
            
            <!-- UPDATE SALES VALIDATION STATUS -->
            <b-tab title="Update Sales Validation Status">
              <div>
                <div class="row">
                  <div class="col-lg-12">
                    <label class="text-label">ID LIST</label>
                    <!-- <Multiselect
                      ref="inputList"
                      v-model="inputList"
                      tag-placeholder="Add new"
                      placeholder="Add sales transaction id"
                      :options="[]"
                      :multiple="true"
                      :taggable="true"
                      @tag="addNewInput"
                    ></Multiselect> -->
                    <textarea
                      v-model="inputList"
                      name="id-list"
                      type="text"
                      class="form-control"
                      placeholder="Add sales transaction id"
                    />
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="updateSales(API_UPDATE_SALES)"
                    >
                      Fail to Pass
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                    <small class="d-block"> API : {{ API_UPDATE_SALES }}</small>
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-outline-primary btn-submit" 
                      :disabled="isLoading"
                      @click="updateSales(API_UPDATE_NEW_SALES)"
                    >
                      New
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                    <small class="d-block"> API : {{ API_UPDATE_NEW_SALES }}</small>
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-submit" 
                      :disabled="isLoading"
                      @click="clearAll"
                    >
                      Clear All
                    </button>
                  </div>
                </div>
                
              </div>            
            </b-tab>

            <!-- EXPORT SERIAL & SALES COMPARISON -->
            <b-tab title="Export Serial & Sales Comparison">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group flex-grow-1">
                    <label class="text-label">Serial No</label>
                    <span class="required-star">*</span>
                    <!-- <input
                      v-model="textInput"
                      name="serial"
                      :disabled="isLoading"
                      type="text"
                      class="form-control"
                      placeholder 
                      @keyup="formErrors.text = ''"
                    /> -->
                    <textarea
                      v-model="inputList"
                      name="id-list"
                      type="text"
                      class="form-control"
                    />
                    <span v-if="formErrors.text" class="invalid-field">{{ formErrors.text }}</span>
                  </div>
                  <div class="form-group">
                    <label class="text-label">Country (only for Serial)</label>
                    <Multiselect
                      ref="country"
                      v-model="filters.countrySelected"
                      :searchable="false"
                      :options="countryOptions"
                      label="name"
                      track-by="value"
                      :loading="!countryOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                </div>
                <div class="col-lg-2">
                  <button
                    ref="button"
                    class="btn btn-sm btn-primary btn-submit" 
                    :disabled="isLoading"
                    @click="exportSSComparison(API_EXPORT_SERIAL_COMPARISON)"
                  >
                    Serial
                    <SpringSpinner
                      v-if="isLoading"
                      class="position-absolute btn-spinner"
                      :size="17"
                      color="#fff"
                    />
                  </button>
                  <small class="d-block"> API : {{ API_EXPORT_SERIAL_COMPARISON }}</small>
                </div>
                <div class="col-lg-2">
                  <button
                    ref="button"
                    class="btn btn-sm btn-primary btn-submit" 
                    :disabled="isLoading"
                    @click="exportSSComparison(API_EXPORT_SALES_COMPARISON)"
                  >
                    Sales
                    <SpringSpinner
                      v-if="isLoading"
                      class="position-absolute btn-spinner"
                      :size="17"
                      color="#fff"
                    />
                  </button>
                  <small class="d-block"> API : {{ API_EXPORT_SALES_COMPARISON }}</small>
                </div>
                <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-submit" 
                      :disabled="isLoading"
                      @click="clearAll"
                    >
                      Clear All
                    </button>
                  </div>
              </div>
            </b-tab>

            <!-- CHANGE SALES STATUS -->
            <b-tab title="Update Sales Status">
              <div>
                <div class="row">
                  <div class="col-lg-12">
                    <label class="text-label">ID LIST</label>
                    <!-- <Multiselect
                      ref="inputList"
                      v-model="inputList"
                      tag-placeholder="Add new"
                      placeholder="Add sales transaction id"
                      :options="[]"
                      :multiple="true"
                      :taggable="true"
                      @tag="addNewInput"
                    ></Multiselect> -->
                    <textarea
                      v-model="inputList"
                      name="id-list"
                      type="text"
                      class="form-control"
                      placeholder="Add sales transaction id"
                    />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-lg-2">
                    <label class="text-label mr-4">Active</label>
                    <input
                      v-model="salesStatusQuery.active"
                      name="duplicate"
                      type="checkbox"
                    />
                  </div>
                  <div class="col-lg-2">
                    <label class="text-label mr-4">Duplicate</label>
                    <input
                      v-model="salesStatusQuery.duplicate"
                      name="duplicate"
                      type="checkbox"
                    />
                  </div>
                  <div class="col-lg-2">
                    <label class="text-label mr-4">Not Entitle</label>
                    <input
                      v-model="salesStatusQuery.notEntitle"
                      name="notEntitle"
                      type="checkbox"
                    />
                  </div>
                  <div class="col-lg-2">
                    <label class="text-label mr-4">Not Entitle Duplicate</label>
                    <input
                      v-model="salesStatusQuery.notEntitleDuplicate"
                      name="notEntitleDuplicate"
                      type="checkbox"
                    />
                  </div>
                </div>
                <div class="row">                    
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="updateSalesStatus"
                    >
                      Submit
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>
                  <div class="col-lg-2">                    
                    <button
                      ref="button"
                      class="btn btn-submit" 
                      :disabled="isLoading"
                      @click="clearAll"
                    >
                      Clear All
                    </button>
                  </div>
                </div>
                <small> API : {{ API_UPDATE_SALES_STATUS }}</small>
              </div>  
            </b-tab>

            <!-- REASSIGN OUTLET SALES -->
            <b-tab title="Reassign Outlet Sales">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group flex-grow-1">
                    <label class="text-label">Outlet Code</label>
                    <span class="required-star">*</span>
                    <input
                      v-model="textInput"
                      name="mdm"
                      :disabled="isLoading"
                      type="text"
                      class="form-control"
                      placeholder 
                      @keyup="formErrors.text = ''"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group flex-grow-1">
                    <label class="text-label">Sales ID List</label>
                    <span class="required-star">*</span>
                    <textarea
                      v-model="inputList"
                      name="id-list"
                      type="text"
                      class="form-control"
                      placeholder="Add sales transaction id"
                    />
                  </div>
                </div>
                <div class="col-lg-2">
                  <button
                    ref="button"
                    class="btn btn-sm btn-primary btn-submit" 
                    :disabled="isLoading"
                    @click="reassignOutletSales"
                  >
                    Submit
                    <SpringSpinner
                      v-if="isLoading"
                      class="position-absolute btn-spinner"
                      :size="17"
                      color="#fff"
                    />
                  </button>
                  <small class="d-block"> API : {{ API_REASSIGN_OUTLET_SALES }}</small>
                </div>
              </div>
            </b-tab>

            <!-- API_TOTAL_PC_SO_CAPACITY_UPLOAD -->
            <b-tab title="Upload Total PC SO Capacity for each outlet">
              <div>
                <div class="row">
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="$refs.file.click();uploadFunc = totalPCSOCapacityUpload"
                    > 
                      <i class="fa fa-upload"></i>
                      Upload
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>
                </div>
                <h3> NOTE: excel template: dealer outlet code + capacity value</h3>
                <h3> NOTE: the first row will treat as header, and will not be processed</h3>
                <small> API : {{ API_TOTAL_PC_SO_CAPACITY_UPLOAD }}</small>
              </div>
            </b-tab>

            <!-- API_RESET_MERCHANDISER_VISIT -->
            <b-tab title="Reset Merchandiser Visitation">
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <label class="text-label">Dealer Outlet Code</label>
                    <span class="required-star">*</span>
                      <input
                        v-model="textInput"
                        name="outlet-code"
                        :disabled="isLoading"
                        type="text"
                        class="form-control"
                        placeholder
                      />
                  </div>
                  <div class="col-lg-4">
                    <label class="text-label">Merchandiser Account ID</label>
                    <span class="required-star">*</span>
                      <input
                        v-model.number="textInput2"
                        name="acc-id"
                        :disabled="isLoading"
                        type="number"
                        class="form-control"
                        placeholder
                      />
                  </div>
                  <div class="col-lg-4">
                    <label class="text-label">Visitation Date</label>
                    <span class="required-star">*</span>
                      <input
                        v-model="textInput3"
                        name="visit-date"
                        :disabled="isLoading"
                        type="text"
                        class="form-control"
                        placeholder
                      />
                      <span>FORMAT: YYYY-MM-DD</span>
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="resetMerchandiseVisit"
                    >
                      Submit
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>

                </div>
                <small> API : {{ API_RESET_MERCHANDISER_VISIT }}</small>
              </div> 
            </b-tab>

            <!-- API_UPLOAD_TARGET_WEEKLY_BY_SUBREGION -->
            <b-tab title="Upload target weekly by sub region">
              <div>
                <div class="row">
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="$refs.file.click();uploadFunc = uploadWeeklyTargetBySubregion"
                    > 
                      <i class="fa fa-upload"></i>
                      Upload
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>
                  <small> API : {{ API_UPLOAD_TARGET_WEEKLY_BY_SUBREGION }}</small>
                  <h3> NOTE: excel template: Year, Quarter, Country, Subregion, Subregion ID, Wk1 to Wk 13</h3>
                  <h3> NOTE: Backend is using: Year, Quarter, Subregion ID, Wk1 to Wk 13</h3>
                  <h3> NOTE: the first row will treat as header, and will not be processed</h3>
                </div>
              </div>
            </b-tab>

            <!-- API_GET_TARGET_WEEKLY_BY_SUB_REGION -->
            <b-tab title="Get target weekly by sub region">
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <label class="text-label">Fiscal Year (FYXXXX)</label>
                    <span class="required-star">*</span>
                      <input
                        v-model="textInput"
                        name="fiscal-year"
                        :disabled="isLoading"
                        type="text"
                        class="form-control"
                        placeholder
                      />
                  </div>
                  <div class="col-lg-4">
                    <label class="text-label">Quarter (QX)</label>
                    <span class="required-star">*</span>
                      <input
                        v-model="textInput2"
                        name="quarter"
                        :disabled="isLoading"
                        type="text"
                        class="form-control"
                        placeholder
                      />
                  </div>
                  <div class="col-lg-4">
                    <label class="text-label">Country Id</label>
                    <span class="required-star">*</span>
                      <input
                        v-model="textInput3"
                        name="countryId"
                        :disabled="isLoading"
                        type="text"
                        class="form-control"
                        placeholder
                      />
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="getWeeklyTargetBySubregion()"
                    >
                      GET
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>
                </div>
                <p> NOTE for country ID: MY: 21, HK: 26, ID: 28, KH: 4722, KR: 4723, PH: 25, ROI: 4721, SG: 22, TH: 23, TW: 27, VN: 24</p>
                <small> API : {{ API_GET_TARGET_WEEKLY_BY_SUB_REGION }}</small>
              </div>
            </b-tab>

            <!-- API_UPDATE_SALES_SRP -->
            <b-tab title="Update Sales SRP from transaction date">
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="$refs.file.click();uploadFunc = (input) => base64Input=input.base64"
                    > 
                      <i class="fa fa-upload"></i>
                      Upload
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                    <div v-if="uploadedFileName">Uploaded file name: {{ uploadedFileName }}</div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-4">
                    <label class="text-label">Invoice Date Range</label>
                    <DateRangePicker
                      ref="picker"
                      v-model="filters.dateRangeSelected"
                      opens="center"
                      :locale-data="{ firstDay: 1, format: 'YYYY-MM-DD' }"
                      :disabled="isLoading" 
                    />
                  </div>
                  <div class="col-lg-4">
                    <label class="text-label">Country</label>
                    <Multiselect
                      ref="country"
                      v-model="filters.countrySelected"
                      :searchable="false"
                      :options="countryOptions.filter((el) => el.name !== 'CAP')"
                      label="name"
                      track-by="value"
                      :loading="!countryOptions || isLoading"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="updateSalesSrp()"
                    >
                      SUBMIT
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>
                </div>
                <small> API : {{ API_UPDATE_SALES_SRP }}</small>
              </div>
            </b-tab>

            <!-- API_UPLOAD_TOTAL_COMMISSION_PAYABLE -->
            <b-tab title="Upload Total Commission Payable">
              <div>
                <div class="row">
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="$refs.file.click();uploadFunc = uploadTotalCommissionPayable"
                    > 
                      <i class="fa fa-upload"></i>
                      Upload
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>
                </div>
                <h3> NOTE: excel template: Country, Fiscal Year, Quarter, Local Currency, Conversion Rate, USD</h3>
                <h3> NOTE: the first row will treat as header, and will not be processed</h3>
                <small> API : {{ API_UPLOAD_TOTAL_COMMISSION_PAYABLE }}</small>
              </div>
            </b-tab>

            <!-- API_UPLOAD_STOCK_ON_HAND -->
            <b-tab title="Upload Stock on hand">
              <div>
                <div class="row">
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="$refs.file.click();uploadFunc = uploadStockOnHand"
                    > 
                      <i class="fa fa-upload"></i>
                      Upload
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>
                </div>
                <p> NOTE: excel template: Country, Outlet Code, Promoter Name, Promoter ID, Stock Count Date ,MTM ,Qty</p>
                <p> NOTE: Backend only takes: Outlet Code, Promoter ID, Stock Count Date, MTM ,Qty</p>
                <p> NOTE: the first row will treat as header, and will not be processed</p>
                <small> API : {{ API_UPLOAD_STOCK_ON_HAND }}</small>

                <a 
                  v-if="errorFileLink"
                  :href="errorFileLink"
                  class="error-file-link"
                >
                  Download error file
                </a>
              </div>
            </b-tab>

            <!-- API_UPLOAD_SKU_GROUP_WEEKLY_TARGET -->
            <b-tab title="Upload SKU Group Weekly Target">
              <div>
                <div class="row">
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="$refs.file.click();uploadFunc = uploadSkuGroupWeeklyTarget"
                    > 
                      <i class="fa fa-upload"></i>
                      Upload
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>
                </div>
                <p> NOTE: excel template: Country,	Fiscal Year,	Quarter,	Target,	W1,	W2,	W3,	W4,	W5,	W6,	W7,	W8,	W9,	W10,	W11,	W12,	W13 </p>
                <p> NOTE: the first row will treat as header, and will not be processed</p>
                <small> API : {{ API_UPLOAD_SKU_GROUP_WEEKLY_TARGET }}</small>

                <a 
                  v-if="errorFileLink"
                  :href="errorFileLink"
                  class="error-file-link"
                >
                  Download error file
                </a>
              </div>
            </b-tab>

              <!-- API_GET_DASHBOARD_DEFAULT_SELECTION -->
              <b-tab title="Update Dashboard Default Selection" active>
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <label class="text-label">Fiscal Year</label>
                    <span class="required-star">*</span>
                      <input
                        v-model="textInput"
                        name="fiscal year"
                        :disabled="isLoading"
                        type="text"
                        class="form-control"
                        placeholder
                      />
                      <span>Current Fiscal Year: {{ currentDashboardSelection.fiscalYear }}</span>
                  </div>
                  <div class="col-lg-4">
                    <label class="text-label">Quarter</label>
                    <span class="required-star">*</span>
                      <input
                        v-model="textInput2"
                        name="quarter"
                        :disabled="isLoading"
                        type="text"
                        class="form-control"
                        placeholder
                      />
                    <span>Current Quarter: {{ currentDashboardSelection.quarter }}</span>
                  </div>
                  <div class="col-lg-2">
                    <button
                      ref="button"
                      class="btn btn-sm btn-primary btn-submit" 
                      :disabled="isLoading"
                      @click="updateDashboardDefaultSelection"
                    >
                      Submit
                      <SpringSpinner
                        v-if="isLoading"
                        class="position-absolute btn-spinner"
                        :size="17"
                        color="#fff"
                      />
                    </button>
                  </div>

                </div>
                <br />
                <br />
                <br />
                <small> API : {{ API_GET_DASHBOARD_DEFAULT_SELECTION }}</small> <br />
                <small> API : {{ API_UPDATE_DASHBOARD_DEFAULT_SELECTION }}</small>
              </div>            
            </b-tab>

            <!-- START: COMMON AREA --> 
            <!-- OBJECT CONTAINER {} --> 
            <div class="m-3 p-4">
              <template v-if="contentObj">
                <object-entry
                  v-for="(value, key) in contentObj" 
                  :key="key"
                  :entry-key="key"
                  :entry-value="value" 
                />
              </template>
              <template v-if="content">
                <b>{{ content }}</b>
              </template>
            </div>
            <!-- FILE PICKER -->
            <input
              id="file"
              ref="file"
              v-validate="'required'"
              type="file"
              name="file"
              accept=".xlsx, .xls"
              class="d-none"
              @change="handleFileUpload($event,uploadFunc)"
            />
            <!-- END: COMMON AREA --> 

          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import { BTabs } from 'bootstrap-vue'
import { SpringSpinner } from "epic-spinners";
import { defaultFiscalYear, defaultQuarter, emailValidate } from '../../utils/helpers';
import { DASHBOARD_TOP_FILTERS_GET } from "@/store/modules/dashboard/actions";
import { COUNTRY_LIST_REQUEST } from "@/store/modules/utility/actions";
import { NOTIFICATION_SUCCESS } from "@/store/modules/ui/actions";
import { EXTENDED_TIMEOUT } from "@/constants/index";
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import objectEntry from "../../components/ui/objectEntry.vue"
import DateRangePicker from "../../components/ui/vue2-daterange-picker";
import cloneDeep from "lodash/cloneDeep"
import Multiselect from "vue-multiselect";
import request from "@/utils/request";

const filterQuery = {
  yearSelected: { name: defaultFiscalYear(), value: defaultFiscalYear() },
  quarterSelected: { name: defaultQuarter(), value: defaultQuarter() },
  countrySelected: { name:"CAP", value:"CAP" },
  userRoleSelected: { code: "UR01", id: 15, role: "Admin" },
  dateRangeSelected: {}
}

const salesQuery = {
  salesIds: "",
  active: false,
  duplicate: false,
  notEntitle: false,
  notEntitleDuplicate: false,
}

const API_DELETE_PROMOTER_TARGET = "/utility/delete-promoter-target"
const API_DEALER_INFO = "/utility/dealer/"
const API_OUTLET_INFO = "/utility/outlet/"
const API_EXPORT_COMPARISON_REPORT = "/utility/comparison/export"
const API_GET_MTM = "/utility/mtm/"
const API_GET_SERIAL = "/utility/product-serial/"
const API_GET_USER = "/utility/app-accounts/"
const API_REFRESH_DASHBOARD = "/utility/refresh-dashboard"
const API_UPLOAD_REVCA = "/dealers/rev-ca/weekly/upload"
const API_UPLOAD_MTM = "/product-sku/upload/product-sku"
const API_DELETE_SALES = "/sales/delete/"
const API_UPDATE_USER_STATUS = "/app-account/update/status"
const API_BACKEND_SO = "/sell-out/bulk/update"
const API_GET_USERROLE = "/utility/user-roles"
const API_UPDATE_USERROLE = "/user-profiles/update/userrole"
const API_UPDATE_SALES = "/sales/update/pass"
const API_UPDATE_NEW_SALES = "/sales/update/new"
const API_EXPORT_SERIAL_COMPARISON = "/product-serial/export"
const API_EXPORT_SALES_COMPARISON = "/sales/export"
const API_UPDATE_SALES_STATUS = "/sales/change-status"
const API_REASSIGN_OUTLET_SALES = "/sales/reassign-sales-dealer-outlet"
const API_TOTAL_PC_SO_CAPACITY_UPLOAD = "/dealer-outlets/upload/total-pc-so-capacity"
const API_RESET_MERCHANDISER_VISIT = "/merchandiser/visitation/reset"
const API_UPLOAD_TARGET_WEEKLY_BY_SUBREGION = "/target/subregions/weekly/upload"
const API_GET_TARGET_WEEKLY_BY_SUB_REGION = "/target/subregions/weekly"
const API_UPDATE_SALES_SRP = "/sales/update-sales-srp"
const API_UPLOAD_TOTAL_COMMISSION_PAYABLE = "/total-commission-payable/upload"
const API_UPLOAD_STOCK_ON_HAND = "/stock-on-hand/upload"
const API_UPLOAD_SKU_GROUP_WEEKLY_TARGET = "/product-sku/upload/sku-group-weekly-target"
const API_GET_DASHBOARD_DEFAULT_SELECTION = "/utility/get/default-selected-option"
const API_UPDATE_DASHBOARD_DEFAULT_SELECTION = "/utility/update/default-selected-option"

export default {
  name: "OperationPage",
  components: {
    breadcrumb,
    'b-tabs': BTabs,
    Multiselect,
    SpringSpinner,
    'object-entry' : objectEntry,
    DateRangePicker,
  },
  data() {
    return {
      isLoading: false,
      yearOptions: [],
      quarterOptions: [],
      countryOptions: [],
      userRoleOptions: [],
      allCountryList: [],
      // inputList: [],
      inputList: '',
      apiStatus: 'open', // open, inProgress, success, failed
      API_DELETE_PROMOTER_TARGET: API_DELETE_PROMOTER_TARGET,
      API_DEALER_INFO: API_DEALER_INFO,
      API_OUTLET_INFO: API_OUTLET_INFO,
      API_EXPORT_COMPARISON_REPORT: API_EXPORT_COMPARISON_REPORT,
      API_GET_MTM: API_GET_MTM,
      API_GET_SERIAL: API_GET_SERIAL,
      API_GET_USER: API_GET_USER,
      API_REFRESH_DASHBOARD: API_REFRESH_DASHBOARD,
      API_UPLOAD_REVCA: API_UPLOAD_REVCA,
      API_UPLOAD_MTM: API_UPLOAD_MTM,
      API_DELETE_SALES: API_DELETE_SALES,
      API_UPDATE_USER_STATUS: API_UPDATE_USER_STATUS,
      API_BACKEND_SO: API_BACKEND_SO,
      API_GET_USERROLE: API_GET_USERROLE,
      API_UPDATE_USERROLE: API_UPDATE_USERROLE,
      API_UPDATE_SALES: API_UPDATE_SALES,
      API_UPDATE_NEW_SALES: API_UPDATE_NEW_SALES,
      API_EXPORT_SERIAL_COMPARISON: API_EXPORT_SERIAL_COMPARISON,
      API_EXPORT_SALES_COMPARISON: API_EXPORT_SALES_COMPARISON,
      API_UPDATE_SALES_STATUS: API_UPDATE_SALES_STATUS,
      API_REASSIGN_OUTLET_SALES: API_REASSIGN_OUTLET_SALES,
      API_TOTAL_PC_SO_CAPACITY_UPLOAD: API_TOTAL_PC_SO_CAPACITY_UPLOAD,
      API_RESET_MERCHANDISER_VISIT: API_RESET_MERCHANDISER_VISIT,
      API_UPLOAD_TARGET_WEEKLY_BY_SUBREGION: API_UPLOAD_TARGET_WEEKLY_BY_SUBREGION,
      API_GET_TARGET_WEEKLY_BY_SUB_REGION: API_GET_TARGET_WEEKLY_BY_SUB_REGION,
      API_UPDATE_SALES_SRP: API_UPDATE_SALES_SRP,
      API_UPLOAD_TOTAL_COMMISSION_PAYABLE: API_UPLOAD_TOTAL_COMMISSION_PAYABLE,
      API_UPLOAD_STOCK_ON_HAND: API_UPLOAD_STOCK_ON_HAND,
      API_UPLOAD_SKU_GROUP_WEEKLY_TARGET: API_UPLOAD_SKU_GROUP_WEEKLY_TARGET,
      API_GET_DASHBOARD_DEFAULT_SELECTION: API_GET_DASHBOARD_DEFAULT_SELECTION,
      API_UPDATE_DASHBOARD_DEFAULT_SELECTION: API_UPDATE_DASHBOARD_DEFAULT_SELECTION,
      filters: cloneDeep(filterQuery),
      textInput: "",
      textInput2: "",
      textInput3: "",
      base64Input: null,
      uploadedFileName: null,
      formErrors: [],
      contentObj: {},
      content: null,
      errorFileLink: null,
      uploadFunc: null,
      isChecked: false,
      salesStatusQuery: cloneDeep (salesQuery),
      currentDashboardSelection: {}
    }
  },
  methods: {
    async getFilters() {
      // Get General Options
      const res = await this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET);
      if (res.content) {
        this.yearOptions = await res.content.fiscalYearDropdown;
        this.countryOptions = await res.content.countryDropdown;
        this.quarterOptions = await res.content.quarterDropdown;
      }
      // Get User Role
      request({
        url: API_GET_USERROLE,
        method: "get",
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.userRoleOptions = cloneDeep(res.content)
        }
      })
    },
    getAllCountry() {
      this.$store.dispatch(COUNTRY_LIST_REQUEST, {}).then((res) => {
        if (res.resultCode !== 0) return

        this.allCountryList = cloneDeep(res.content)
      })
    },
    getDashboardDefaultSelection() {
      request({
        url: API_GET_DASHBOARD_DEFAULT_SELECTION,
        method: "post",
        data: {
          code: "dashboard_fiscal_year_quarter"
        },
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.currentDashboardSelection = cloneDeep(res.content)
        }
      })
      .finally(() => this.isLoading = false)
    
    },
    resetForm() {
      this.formErrors = []
      this.textInput = ""
      this.textInput2 = ""
      this.textInput3 = ""
      this.base64Input = null
      this.uploadedFileName = null
      this.filters = cloneDeep(filterQuery)
      this.salesStatusQuery = cloneDeep (salesQuery)
      this.uploadFunc = null
      this.isChecked = false
      this.inputList = ''
      this.clearContent()
      this.errorFileLink = null
      this.apiStatus = 'open'
    },
    clearContent() {
      this.contentObj = {}
      this.content = null
    },
    checkFormValidity(isEmail) {
      this.formErrors = {}
      let haveError = false
      
      if (this.textInput == null || this.textInput == "") {
        this.formErrors.text = "Input is required."
        haveError = true
      }

      if (isEmail) {
        if(!emailValidate(this.textInput)){
          this.formErrors.text = "Invalid email";
          haveError = true
        }
      }

      return haveError == false
    },
    // ---Multiselect
    addNewInput(newId) {
      this.inputList.push(newId)
      this.$refs.inputList.$el.focus()
    },
    clearAll() {
      this.inputList = []
      this.filters = cloneDeep(filterQuery)
    },
    // !--- Multiselect
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(btoa(reader.result));
        reader.onerror = error => reject(error);
        reader.readAsBinaryString(file);
      });
    },
    handleFileUpload(event,apiFunc) {
      const file = event.target.files[0];
      if(file) {
        let pdata = {}
        this.uploadedFileName = file.name
        this.getBase64(file).then(data => {
          pdata.base64 = data;
          apiFunc(pdata)
        })
      }
    },
    deletePromoterTarget() {
      this.isLoading = true
      let api = `${API_DELETE_PROMOTER_TARGET}/${this.filters.yearSelected.value}/${this.filters.quarterSelected.value}`
      console.log('API_DELETE_PROMOTER_TARGET:',api)
      this.apiStatus = 'inProgress'
      request({
        url: api,
        method: "get",
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Deleted Successfully"
          });
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    getDealerInfo() {
      if (!this.checkFormValidity()) return;
      this.isLoading = true
      this.clearContent()
      let api = `${API_DEALER_INFO}${this.textInput}`
      console.log('API_DEALER_INFO:',api)
      this.apiStatus = 'inProgress'
      request({
        url: api,
        method: "get",
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          if(typeof res.content !== 'string') {
            this.contentObj = res.content
          } else {
            this.content = res.content
          }
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)

    },
    getOutletInfo() {
      if (!this.checkFormValidity()) return;
      this.isLoading = true
      this.clearContent()
      let api = `${API_OUTLET_INFO}${this.textInput}`
      console.log('API_OUTLET_INFO:',api)
      this.apiStatus = 'inProgress'
      request({
        url: api,
        method: "get",
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          if(typeof res.content !== 'string') {
            this.contentObj = res.content
          } else {
            this.content = res.content
          }
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)

    },
    exportComparisonReport() {
      this.isLoading = true;
      let api = `${API_EXPORT_COMPARISON_REPORT}?fyf=${this.filters.yearSelected.value}&qf=${this.filters.quarterSelected.value}&ccf=${this.filters.countrySelected.value}`
      console.log('API_EXPORT_COMPARISON_REPORT:',api)
      this.apiStatus = 'inProgress'
      request({
        url: api,
        method: "get",
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          if(!res.content.link) return
          const url = res.content.link;
          window.location.href = url;
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    getMTM() {
      if (!this.checkFormValidity()) return;
      this.isLoading = true
      this.clearContent()
      let api = `${API_GET_MTM}${this.textInput}`
      console.log('API_GET_MTM:',api)
      this.apiStatus = 'inProgress'
      request({
        url: api,
        method: "get",
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          if(typeof res.content !== 'string') {
            this.contentObj = res.content
          } else {
            this.content = res.content
          }
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)

    },
    getSerial() {
      if (!this.checkFormValidity()) return;
      this.isLoading = true
      this.clearContent()
      let api = `${API_GET_SERIAL}${this.textInput}`
      console.log('API_GET_SERIAL:',api)
      this.apiStatus = 'inProgress'
      request({
        url: api,
        method: "get",
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          if(typeof res.content !== 'string') {
            this.contentObj = res.content
          } else {
            this.content = res.content
          }
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)

    },
    getUser() {
      if (!this.checkFormValidity(true)) return;
      this.isLoading = true
      this.clearContent()
      let api = `${API_GET_USER}${this.textInput}`
      console.log('API_GET_USER:',api)
      this.apiStatus = 'inProgress'
      request({
        url: api,
        method: "get",
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          if(typeof res.content !== 'string') {
            this.contentObj = res.content
          } else {
            this.content = res.content
          }
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)

    },
    refreshDashboard() {
 
      this.$bvModal.msgBoxConfirm('Please confirm that you want to refresh dashboard.', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'CANCEL',
        hideHeaderClose: false,
      })
        .then(value => {
          if(value){
            this.isLoading = true;
            let api = `${API_REFRESH_DASHBOARD}/${this.filters.countrySelected.value}/${this.filters.yearSelected.value}/${this.filters.quarterSelected.value}`
            console.log('API_REFRESH_DASHBOARD:',api)
            this.apiStatus = 'inProgress'
            request({
              url: api,
              method: "get",
              timeout: EXTENDED_TIMEOUT
            }).then(res => {
              if (res.resultCode === 0) {
                this.$store.dispatch(NOTIFICATION_SUCCESS, {
                  message: "Refreshing Dashboard..."
                });
                this.apiStatus = 'success'
              } else {
                this.apiStatus = 'failed'
              }
            }).finally(() => this.isLoading = false)
          } 
        })
        .catch(err => {
          // An error occurred
          console.log(err)
          this.apiStatus = 'failed'
        })

      
    },
    uploadREVCA(file64) {
      this.isLoading = true
      this.apiStatus = 'inProgress'
      request({
        url: API_UPLOAD_REVCA,
        method: "post",
        data: file64,
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Uploaded Successfully"
          });
          if(!res.content.link) return
          const url = res.content.link;
          window.open(url);
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    uploadMTM(file64) {
      this.isLoading = true
      this.apiStatus = 'inProgress'
      request({
        url: API_UPLOAD_MTM,
        method: "post",
        data: file64,
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Uploaded Successfully"
          });
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    deleteSales() {
      if (!this.checkFormValidity()) return;
      this.isLoading = true
      this.apiStatus = 'inProgress'
      let api = `${API_DELETE_SALES}${this.textInput}`
      console.log('API_DELETE_SALES:',api)
      request({
        url: api,
        method: "delete",
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Deleted Successfully"
          });
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    updateUserStatus() {
      if (!this.checkFormValidity(true)) return;
      this.isLoading = true
      this.apiStatus = 'inProgress'
      let pdata = {
        username: this.textInput,
        status: this.isChecked
      }
      console.log('API_UPDATE_USER_STATUS:',pdata)
      request({
        url: API_UPDATE_USER_STATUS,
        method: "put",
        data: pdata,
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Updated Successfully"
          });
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    backendSO(file64) {
      this.isLoading = true
      this.apiStatus = 'inProgress'
      request({
        url: API_BACKEND_SO,
        method: "post",
        data: file64,
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Uploaded Successfully"
          });
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    updateUserRole() {
      if (!this.checkFormValidity(true)) return;
      this.isLoading = true;
      this.apiStatus = 'inProgress'
      let api = `${API_UPDATE_USERROLE}?username=${this.textInput}&roleId=${this.filters.userRoleSelected.id}`
      console.log('API_UPDATE_USERROLE:',api)
      request({
        url: api,
        method: "post",
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Updated Successfully"
          });
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    updateSales(api) {
      if(this.inputList.length <= 0) return;
      this.isLoading = true
      this.apiStatus = 'inProgress'
      let idList = this.formatStringToArr(this.inputList)
      let pdata = {
        idList: idList
      }
      console.log(`${api}:`,pdata)
      request({
        url: api,
        method: "put",
        data: pdata,
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Updated Successfully"
          });
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    exportSSComparison(apiLink) {
      if(this.inputList.length <= 0) return;
      this.isLoading = true
      this.apiStatus = 'inProgress'
      // let idList = this.formatStringToArr(this.inputList)
      let serialNoList = this.inputList
      let ccf = this.filters.countrySelected.value

      request({
        url: apiLink,
        method: "post",
        data: {
          serialNoList,
          ccf
        },
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          if(!res.content.link) return
          const url = res.content.link;
          window.open(url);
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    totalPCSOCapacityUpload(file64) {
      this.isLoading = true
      this.apiStatus = 'inProgress'
      request({
        url: API_TOTAL_PC_SO_CAPACITY_UPLOAD,
        method: "post",
        data: file64,
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Uploaded Successfully"
          });
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    formatStringToArr(arr) {      
      return arr.split(",");
    },
    formatArrtoString(arr) {      
      return arr.map(function(id) { return "'" + id + "'"; }).join(", ");
    },
    updateSalesStatus() {
      if(this.inputList.length <= 0) return;
      // let salesIds = this.formatArrtoString(this.inputList)
      this.isLoading = true
      this.apiStatus = 'inProgress'
      let pdata = this.salesStatusQuery
      pdata.salesIds = this.inputList
      console.log(`${API_UPDATE_SALES_STATUS}:`,pdata)
      request({
        url: API_UPDATE_SALES_STATUS,
        method: "post",
        data: pdata,
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Updated Successfully"
          });
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    reassignOutletSales() {
      if (!this.checkFormValidity() || this.inputList.length <= 0) return;
      this.isLoading = true
      this.apiStatus = 'inProgress'
      let idList = this.inputList
      let pdata = {
        outletCode: this.textInput,
        salesIdList: idList
      }
      console.log(`${API_REASSIGN_OUTLET_SALES}:`,pdata)
      request({
        url: API_REASSIGN_OUTLET_SALES,
        method: "post",
        data: pdata,
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Updated Successfully"
          });
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    resetMerchandiseVisit() {
      if(this.textInput === "" || this.textInput2 === "" || this.textInput3 === "") return;
      this.isLoading = true
      this.apiStatus = 'inProgress'
      let pData = {
        dealerOutletCode: this.textInput,
        appAccountId: this.textInput2,
        visitationDate: this.textInput3,
      }
      console.log(pData)
      request({
        url: this.API_RESET_MERCHANDISER_VISIT,
        method: "post",
        data: pData,
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Updated Successfully"
          });
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    uploadWeeklyTargetBySubregion(file64) {
      this.isLoading = true
      this.apiStatus = 'inProgress'
      request({
        url: API_UPLOAD_TARGET_WEEKLY_BY_SUBREGION,
        method: "post",
        data: file64,
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Uploaded Successfully"
          });
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    getWeeklyTargetBySubregion() {
      this.isLoading = true
      this.apiStatus = 'inProgress'
      request({
        url: API_GET_TARGET_WEEKLY_BY_SUB_REGION,
        method: "post",
        data: {
          fiscalYear: this.textInput,
          quarter: this.textInput2,
          countryId: this.textInput3
        },
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.apiStatus = 'success'
          this.contentObj = res.content
          .map(el => {
              delete el.createdDate
              delete el.updatedDate
              delete el.quarterNumeric
              return el
            })
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    updateSalesSrp() {
      this.isLoading = true
      this.apiStatus = 'inProgress'

      let country = this.allCountryList.find(
        (el) => el.code === this.filters.countrySelected.value
      )
      let countryId = country ? country.id : null

      let data = {
        base64: this.base64Input,
        fromInvoiceDate: this.filters.dateRangeSelected.startDate.getTime(),
        toInvoiceDate: this.filters.dateRangeSelected.endDate.getTime(), // optional
        countryId
      }
      console.log(data)
      request({
        url: API_UPDATE_SALES_SRP,
        method: "post",
        data,
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        const status = res.content.status
        if (status === "ok") {
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    uploadTotalCommissionPayable(file64) {
      this.isLoading = true
      this.apiStatus = 'inProgress'
      request({
        url: API_UPLOAD_TOTAL_COMMISSION_PAYABLE,
        method: "post",
        data: file64,
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Uploaded Successfully"
          });
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    uploadStockOnHand(file64) {
      this.errorFileLink = null
      this.isLoading = true
      this.apiStatus = 'inProgress'
      request({
        url: API_UPLOAD_STOCK_ON_HAND,
        method: "post",
        data: file64,
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0 && res.content === "ok") {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Uploaded Successfully"
          });
          this.apiStatus = 'success'
        } else {
          this.errorFileLink = res.content
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    uploadSkuGroupWeeklyTarget(file64) {
      this.errorFileLink = null
      this.isLoading = true
      this.apiStatus = 'inProgress'
      request({
        url: API_UPLOAD_SKU_GROUP_WEEKLY_TARGET,
        method: "post",
        data: file64,
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0 && res.content === "ok") {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Uploaded Successfully"
          });
          this.apiStatus = 'success'
        } else {
          this.errorFileLink = res.content
          this.apiStatus = 'failed'
        }
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    },
    updateDashboardDefaultSelection() {
      if(this.textInput === "" || this.textInput2 === "") return;
      this.isLoading = true
      this.apiStatus = 'inProgress'
      let pData = {
        fiscalYear: this.textInput,
        quarter: this.textInput2,
        code: "dashboard_fiscal_year_quarter",
      }
      console.log(pData)
      request({
        url: this.API_UPDATE_DASHBOARD_DEFAULT_SELECTION,
        method: "put",
        data: pData,
        timeout: EXTENDED_TIMEOUT
      }).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Updated Successfully"
          });
          this.apiStatus = 'success'
        } else {
          this.apiStatus = 'failed'
        }
        this.getDashboardDefaultSelection()
      })
      .catch(() => this.apiStatus = 'failed')
      .finally(() => this.isLoading = false)
    }
  },
  mounted() {
    this.getFilters();
    this.getAllCountry();
    this.getDashboardDefaultSelection();
  }
};
</script>

<style lang="scss">
#operation-page {
  .tabs {
    ul{
      width: 100%;
      max-height: 70vh;
      overflow: hidden scroll;
      display: block;
    }
    li {
        width: 100%;
        a {
          text-align: left;
          align-items: unset;
          display: block;
        }
    }
  }
  .content {
    min-height:500px; 
  }
  .btn-submit {
    margin-top:24px;
    height:40px;
    width: 100%;
    span {
      top: 50%; 
      left: 50%; 
      transform: translate(-50%, -50%);
    }
    .btn-spinner {
      margin: 0;
      top: 33%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .alert {
    border-radius: 0;
    padding: 0.8rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #df2928;
    border-color: transparent;
    background-color: #fbe7e1;
    margin-top: 2rem;
  }
  .vue-daterange-picker {
    display: block;
  }
  .error-file-link {
    font-size: 2rem;
    text-decoration: underline;
    color: red;
    border-bottom: none;
  }
}
</style>